import { Settings as defaults } from "./settings.default";
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import merge from "lodash.merge";
import { PaymentTypeEnum } from "@application/types/PaymentType.enum";
import { LANGUAGE } from "@config/lang";
import { SEARCH_TYPES } from "@config/const/enums";

export const Settings = merge(defaults, {
  endpoint: "https://admin.euroturist.com.mk/",
  languages: [LANGUAGE.MK, LANGUAGE.SQ, LANGUAGE.EN],
  encodeUrl: false,
  hashRouter: false,
  defaultLanguage: "mk",
  googleAnalyticsTrackingId: null,
  links: {
    top: ["phone", "email", "b2b", "lang"],
    menu: [],
  },
  menuLinks: [
    {
      name: "Rent a Car",
      url: "http://rentacar.erafly.com/renta_c.asp",
      target: "_blank",
    },
  ],
  contact: {
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2964.6206493524555!2d20.96705051168842!3d42.008416271107784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1353f0ebe9e0b977%3A0x69bc1034e868498d!2sEuro%20Turist!5e0!3m2!1sen!2smk!4v1686417260620!5m2!1sen!2smk",
  },
  social: [
    {
      name: "Facebook",
      Icon: AiFillFacebook,
      url: "https://facebook.com/euroturist/",
    },
    {
      name: "Instagram",
      Icon: AiFillInstagram,
      url: "https://www.instagram.com/euroturistt/",
    },
  ],
  search: {
    order: [SEARCH_TYPES.charter, SEARCH_TYPES.hotel, SEARCH_TYPES.mkhotel],
    charter: {
      displayDepartures: true,
      extraDays: 1,
    },
    hotel: {
      displayDepartures: false,
    },
    mkhotel: {
      displayDepartures: false,
    },
  },
  booking: {
    show_extend_hotel_night: false,
    passengers: {
      require_passport_no: true,
    },
    payments: [
      {
        type: PaymentTypeEnum.Cash,
        label: "payments.cash",
        public: true,
        agent: false,
      },
      {
        type: PaymentTypeEnum.CreditCard,
        label: "payments.cc",
        public: true,
        agent: false,
      },
      {
        type: PaymentTypeEnum.Invoice,
        label: "payments.inv",
        public: false,
        agent: true,
      },
    ],
  },
  iframe: {
    allowBooking: false,
  },
});
